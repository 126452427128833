.jobcard{

    /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);*/
    display: flex;
    flex-direction: row;
    gap: 20px;
    /*margin-left: 10px;*/
    justify-content: left;
    padding-top: 10px;
    padding-: 10px;
    margin: 1% 7%;
}

.jobsummary{
    max-width: 60%;
    margin-right: auto;
    padding-left: 20px;
    border-radius: 10px;
}


.jobsummary:hover {
    background: #112549;
}

.techstack{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px;
    list-style-type: none;
}

.tech{
    background: #14304c;
    color: #5fe6c6;
    border-radius: 10px / 15px;
    padding: 5px 20px;
}

.myname{
    font-size: 60px;
    margin-right: 10px;
    align-self: start;
    margin-bottom: 0;
}

.landingcontent{
    margin: 5% 20%;
    display: flex;
    flex-direction: row;
}

.workandschool{

}

.timespan
{
    font-size: small;
    font-weight: lighter;
    margin-left: auto;
    margin-top: 30px;
}

.summaryside{
    display: flex;
    flex-direction: column;
    margin: 10px;
    height: 800px;
}

.finalitem{
    margin-top: auto;
    display: flex;
    gap: 9px;
    align-items: center;
}

.vl{
    margin-top: 50px;
    border-bottom: 2px solid #cad6f6;
    max-width: 80%;
    margin: auto;
    padding-top: 20px;

}

a, a:visited{
    color:#cad6f6;
}
.Header {
    list-style: none;
    padding: 1rem;
    width: 90%;
    /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);*/
    /*border-radius: 6px;*/
    display: flex;
    align-content: space-between;
    justify-content: right;
    flex-wrap: wrap;
    gap: 30px;
    font-size: 30px;
    align-items: flex-end;

}

.first-child {
    margin-right: 40px;
    font-size: larger;
    color: #5fe6c6;
}

.undered {
    border-bottom: 2px solid  #cad6f6;
}

.clickable{
    cursor: pointer;
}


strong {
    color: #5fe6c6;
}
.programmingcontent{
    margin: 7% 20%;
    display: flex;
    flex-direction: row;
    min-width: 50px;
}

.progsummary{
    max-width: 40%;
}

.projects{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px;
    font-size: 20px;
    margin-left: 150px;
    margin-top: 30px;
}
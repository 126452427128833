
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif,'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0a192f;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  color: #cad6f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

